





















import {CampoClass} from "@/Classes/CampoClass";

interface ServiziCampo {
  flag:boolean,
  nome:string,
  descrizione:string,
  id:number
}
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import CampiService, {CampoResponse} from "@/services/CampiService";
import EditFasce from "@/components/editFasce.vue";
@Component
export default class editServiziCampo extends Vue {
  @Prop() campo !:CampoClass
  @Prop() boolCampo !: boolean
  public service:ServiziCampo[] = []



  @Emit()
  SettaBoolAFalse() {
    this.$emit("SettaBoolAFalse")
  }
  public async mounted() {
    const respose = await CampiService.serviziCampo()
    if(respose.error){
      return;
    }
    this.service = respose.data.map(x => {
      let flag:boolean =false;
      return {
        nome: x.nome,
        id: x.id,
        descrizione: x.descrizione,
        flag: flag
      }});


  }
  @Watch('boolCampo')
  public variaCampo(){
    if(this.boolCampo){
      if(this.campo != null){
        for(let x of this.service)
          x.flag = false;
        }
        for(const service of this.campo.servizi_campo){
          for(let x of this.service)
            if(service.id === x.id){
              x.flag = true;
              break;
            }
        }
      this.SettaBoolAFalse();
      }
    }

  public settaServizi(){
    this.$emit('settaServizi',this.service)
  }
}
