





































































































































































































import {Component, Vue} from "vue-property-decorator";
import { Loading } from 'element-ui';
import SportService from "@/services/SportService";
import CampiService, {CampoRequest} from "@/services/CampiService";
import {CampoClass, checkCampoRules, checkSport} from "@/Classes/CampoClass";
import {DateTime} from "luxon";
import EditFasce from "@/components/editFasce.vue";
import editServiziCampo from "@/components/editServiziCampo.vue";
@Component({
  components: {
    EditFasce,
    editServiziCampo
  }
})
export default class AddField extends Vue {
  //Data
  public listaSuperfici:any = [];
  public serviziSelezionati:any = [];
  public modificata_disponibilita:boolean = true;
  public form: CampoClass = new CampoClass();
  public idStruttura:number=-1;
  public maxDurata: number = 0;
  public startTime: any = null;
  public step1:boolean=false;
  public sport: checkSport[] = [];
  public rule: checkCampoRules = {
    nome: [{required: true, message: 'Nome del campo obbligatorio', trigger: 'blur'}],
    descrizione: [{required: true, message: 'Descrizione obbligatoria', trigger: 'blur'}],
    tipo_superfice: [{required: true, message: 'specificare la superficie del campo', trigger: 'blur'}]
  }

  public Back(){

    this.$confirm('Continuando verranno eliminae tutte le fasce orarie generate, vuoi continuare?')
    .then(_ => {
      for(let disp of this.form.disponibilita){
          disp.orario ="";
          disp.orarioApertura ="";
          disp.orarioChiusura ="";
          disp.endTime =null;
          disp.startTime =null;
          disp.fasce = []
      }
      this.step1=false;
      this.modificata_disponibilita=true;
    })
    .catch(_ => {});
  }
  //method
  public getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  public async AggiungiCampo() {
    const campiForm = this.$refs.form as any;
    campiForm.validate(async (valid: boolean) => {
      if (valid) {
        if(!this.step1)
        {
          this.step1=true
          for(let disponibilita of this.form.disponibilita){
              this.form.disponibilita[disponibilita.giorno_settimana-1].startTime = DateTime.fromISO(disponibilita.orarioApertura).toFormat("HH:mm");
              this.form.disponibilita[disponibilita.giorno_settimana-1].endTime = DateTime.fromISO(disponibilita.orarioChiusura).toFormat("HH:mm");
              await this.CalcoloIntervalli(disponibilita.giorno_settimana-1)
          }
          this.modificata_disponibilita = true;
          return
        }

        let loadingInstance1 = Loading.service({ fullscreen: true });
        const campo: CampoRequest = {
          nome: this.form.nome,
          descrizione: this.form.descrizione,
          media: [],
          tipo_superfice: this.form.tipo_superfice,
          servizi_campo: this.serviziSelezionati,
          sport: [],
          flex: this.form.flex,
          color:this.form.color,
          disponibilita: []
        }
        if(!this.form.color)
          campo.color =this.getRandomColor();

        for (let i in this.sport) {
          const prezzo = this.sport[i].prezzo.toFixed(2).replace('.', '').replace(',', '');
          if (this.sport[i].check)
            campo.sport.push({
              id: this.sport[i].id,
              prezzo: parseInt(prezzo),
              durata: this.sport[i].durata,
              pagamento_persona: this.sport[i].pagamento_persona
            })
        }
        for (let disponibilita of this.form.disponibilita) {
          let giorno_settimana = disponibilita.giorno_settimana;
          for(let fascia of disponibilita.fasce){
            campo.disponibilita.push({
              'giorno_settimana' : giorno_settimana,
              'ora_inizio':fascia.inizio,
              'ora_fine': fascia.fine,
            })
          }
        }
        const response = await CampiService.addCampo(this.idStruttura, campo);

        loadingInstance1.close()
        if(!response.error){
          this.$message({
            message: 'Campo aggiunto con successo',
            type: 'success'
          });
          this.$router.push("/campi");
        }
        else {

          this.$message.error(response.msg);
        }
      }
    });
  }
  public async RicercaSport() {
    try {
      const response = await SportService.ListaSport();
      console.log(response.data)
      if (response.error) {
        this.$message.error(response.msg);
      } else {
        for (let i in response.data) {
          const temp: checkSport = {
            nome: response.data[i].nome,
            id: response.data[i].id,
            num_giocatori: response.data[i].num_giocatori,
            check: false,
            borderColor: "Red",
            prezzo: response.data[i].prezzo /100,
            durata: response.data[i].minuti,
            pagamento_persona: false
          };
          this.sport.push(temp);
        }
        this.sport[0].check = true;
      }
    } finally {
      this.calcoloDurataMax()
    }
  }

  async CalcoloIntervalli(i: number) {
    let intervallo ={intervalli: await this.form.CalcoloIntervalli(this.calcoloDurataMax(), i),
      inizio: DateTime.fromISO(this.form.disponibilita[i].startTime).toFormat("HH:mm"),
      fine:DateTime.fromISO(this.form.disponibilita[i].endTime).toFormat("HH:mm")
    }
    this.form.disponibilita[i].fasce.push(intervallo)
    this.form.disponibilita[i].endTime = "";
    for (let j of this.form.disponibilita[i].fasce)
    {
      for (let x of j.intervalli)
      {
        this.form.disponibilita[i].startTime = x.fine;
      }
    }
  }

  calcoloDurataMax() {
    for (let i of this.sport) {
      if (i.durata > this.maxDurata && i.check)
        this.maxDurata = i.durata
    }
    return this.maxDurata;
  }
  controlloCheckSport() {
    for (let i of this.sport) {
      if(i.check)
        return true
    }
    return false;
  }
  cambioOrarioSport(item:checkSport){
    if(!this.controlloCheckSport()){
      this.$message({
        message: 'Devi selezionare almeno uno sport',
        type: 'warning'
      });
      item.check = true;
    }
    const durataMax:number = this.maxDurata;
    this.calcoloDurataMax()
    if(durataMax != this.maxDurata) {
    }
  }
  public async mounted() {
    this.idStruttura = +this.$route.params.id;
    this.modificata_disponibilita = false;
    const responseTipiSuperfice = await CampiService.tipoSuperfici();
    this.listaSuperfici = responseTipiSuperfice.data.map(x => {
        return {
          label: x.nome,
          value: x.id
        }
    });
    await this.form.riempiFasciaOraria(this.idStruttura);
    this.RicercaSport();
  }

  //EMIT
  SettaServizi(n: any) {
    const temp = n.filter((x: { flag: any; }) => {
      return x.flag
    });
    this.serviziSelezionati = temp.map((x:{id:number}) => {
      return x.id
    });
  }
  SettaModificato(n: boolean) {
    this.modificata_disponibilita = n;
  }
  async CalcoloIntervalli_emit(object1: any) {
    const object =object1.object
    const key = object1.kay;
    this.form.disponibilita[key].startTime =object.start_time
    this.form.disponibilita[key].endTime =object.end_time
    let intervallo ={intervalli: await this.form.CalcoloIntervalli(this.calcoloDurataMax(), key),
      inizio:object.start_time,
      fine:object.end_time
    }
    const oraInizio = DateTime.fromISO(object.start_time)
    const oraFine = DateTime.fromISO(object.end_time)
    let flag = false;
    for(let fascia of this.form.disponibilita[key].fasce){
      if((oraInizio >=  DateTime.fromISO(fascia.inizio) && oraInizio <=  DateTime.fromISO(fascia.fine)) ||
          (oraFine >=  DateTime.fromISO(fascia.inizio) && oraFine <=  DateTime.fromISO(fascia.fine)) ||
          (oraFine >=  DateTime.fromISO(fascia.inizio) && oraFine <=  DateTime.fromISO(fascia.fine)) ||
          (oraInizio <  DateTime.fromISO(fascia.inizio) && oraFine >  DateTime.fromISO(fascia.fine))
      ){
        flag = true;
        break;
      }
    }
    if(flag)
    {
      this.$message.error("La nuova fascia non puo essere inserita poichè è compresa in una fascia gia esistente")
      return;
    }else{
      this.form.disponibilita[key].fasce.push(intervallo)
      this.modificata_disponibilita=true

    }
  }

  async elimina_emit(object: any) {
    const giorni = this.form.disponibilita;
    for(let i in giorni){
      if(giorni[i].nome === object.giorno){
        for(let y in giorni[i].fasce)
          if(object.orario_chiusura === giorni[i].fasce[y].fine && object.orario_apertura === giorni[i].fasce[y].inizio ){
            this.form.disponibilita[i].fasce.splice(+y,1)
            this.modificata_disponibilita=true
            return
          }
      }
    }
  }
}

